import {Constants} from "@amzn/dolphin-web-framework";
import {NOTIFICATION_SERVICE, DOLPHIN_AUTH_SERVICE, DOLPHIN_AUTH_API_KEY} from "../../../constants/BaseUrls";

export function getLoggerUrl() {
    return "https://zn20tufct7.execute-api.us-west-2.amazonaws.com/prod/v1/log";
}

export const getRegion = () => {
    const hostname = window.location.hostname;
    if (hostname.startsWith(Constants.RegionLowerCase.REGION_NA)) return Constants.Region.REGION_NA;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_EU)) return Constants.Region.REGION_EU;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_FE)) return Constants.Region.REGION_FE;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_IN)) return Constants.Region.REGION_IN;
    return Constants.Region.REGION_NA;
}

export function getStage() {
    const hostname = window.location.hostname.split('.');
    if (hostname[0] != null) {
        const stage = hostname[0];
        if (stage.includes(Constants.Stage.INTEG)) return Constants.Stage.BETA;
        else if (stage.includes(Constants.Stage.MASTER)) return Constants.Stage.GAMMA;
        else if (stage.includes(Constants.Stage.PROD)) return Constants.Stage.PROD;
    }
    return Constants.Stage.GAMMA;
}

export const getNotificationServiceBaseURL = () => {
    const region = getRegion();
    switch (getStage()) {
        case Constants.Stage.BETA:
            switch (region) {
                case Constants.Region.REGION_NA:
                    return NOTIFICATION_SERVICE.NA.BETA;
                case Constants.Region.REGION_EU:
                    return NOTIFICATION_SERVICE.EU.BETA;
                case Constants.Region.REGION_FE:
                    return NOTIFICATION_SERVICE.FE.BETA;
                // no default
            }
            /* istanbul ignore next */
            break;
        case Constants.Stage.GAMMA:
            switch (region) {
                case Constants.Region.REGION_NA:
                    return NOTIFICATION_SERVICE.NA.GAMMA;
                case Constants.Region.REGION_EU:
                    return NOTIFICATION_SERVICE.EU.GAMMA;
                case Constants.Region.REGION_FE:
                    return NOTIFICATION_SERVICE.FE.GAMMA;
                // no default
            }
            /* istanbul ignore next */
            break;
        case Constants.Stage.PROD:
            switch (region) {
                case Constants.Region.REGION_NA:
                    return NOTIFICATION_SERVICE.NA.PROD;
                case Constants.Region.REGION_EU:
                    return NOTIFICATION_SERVICE.EU.PROD;
                case Constants.Region.REGION_FE:
                    return NOTIFICATION_SERVICE.FE.PROD;
                // no default
            }
            /* istanbul ignore next */
            break;
        // no default
    }
};

export const getDolphinAuthBaseURL = () => {
    const region = getRegion();
    switch (getStage()) {
        case Constants.Stage.BETA:
            switch (region) {
                case Constants.Region.REGION_NA:
                    return DOLPHIN_AUTH_SERVICE.NA.BETA;
                case Constants.Region.REGION_EU:
                    return DOLPHIN_AUTH_SERVICE.EU.BETA;
                case Constants.Region.FE:
                    return DOLPHIN_AUTH_SERVICE.FE.BETA;
                // no default
            }
            /* istanbul ignore next */
            break;
        case Constants.Stage.GAMMA:
            switch (region) {
                case Constants.Region.REGION_NA:
                    return DOLPHIN_AUTH_SERVICE.NA.GAMMA;
                case Constants.Region.REGION_EU:
                    return DOLPHIN_AUTH_SERVICE.EU.GAMMA;
                case Constants.Region.FE:
                    return DOLPHIN_AUTH_SERVICE.FE.GAMMA;
                // no default
            }
            /* istanbul ignore next */
            break;
        case Constants.Stage.PROD:
            switch (region) {
                case Constants.Region.REGION_NA:
                    return DOLPHIN_AUTH_SERVICE.NA.PROD;
                case Constants.Region.REGION_EU:
                    return DOLPHIN_AUTH_SERVICE.EU.PROD;
                case Constants.Region.FE:
                    return DOLPHIN_AUTH_SERVICE.FE.PROD;
                // no default
            }
            /* istanbul ignore next */
            break;
        // no default
    }
};

export const getDolphinAuthAPIKey = () => {
    const region = getRegion();
    switch (getStage()) {
        case Constants.Stage.BETA:
            switch (region) {
                case Constants.Region.REGION_NA:
                    return DOLPHIN_AUTH_API_KEY.NA.BETA;
                case Constants.Region.REGION_EU:
                    return DOLPHIN_AUTH_API_KEY.EU.BETA;
                case Constants.Region.FE:
                    return DOLPHIN_AUTH_API_KEY.FE.BETA;
                // no default
            }
            /* istanbul ignore next */
            break;
        case Constants.Stage.GAMMA:
            switch (region) {
                case Constants.Region.REGION_NA:
                    return DOLPHIN_AUTH_API_KEY.NA.GAMMA;
                case Constants.Region.REGION_EU:
                    return DOLPHIN_AUTH_API_KEY.EU.GAMMA;
                case Constants.Region.FE:
                    return DOLPHIN_AUTH_API_KEY.FE.GAMMA;
                // no default
            }
            /* istanbul ignore next */
            break;
        case Constants.Stage.PROD:
            switch (region) {
                case Constants.Region.REGION_NA:
                    return DOLPHIN_AUTH_API_KEY.NA.PROD;
                case Constants.Region.REGION_EU:
                    return DOLPHIN_AUTH_API_KEY.EU.PROD;
                case Constants.Region.FE:
                    return DOLPHIN_AUTH_API_KEY.FE.PROD;
                // no default
            }
            /* istanbul ignore next */
            break;
        // no default
    }
};